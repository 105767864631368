import { createRouter, createWebHashHistory } from 'vue-router';
import { vueRouterGuard } from '@/classes/Customer';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/404.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    alias: '/password-reset',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    props: (route) => {
      const resetToken = route.query.uuid;
      return {
        resetToken,
      }
    },
  },
  {
    path: '/:customerId/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    beforeEnter: vueRouterGuard,
    props: (route) => {
      const {customerId} = route.params;
      return {
        customerId,
      }
    },
  },
  {
    path: '/:customerId',
    redirect: (to) => {
      return { path: `/${to.params.customerId}/organizer` }
    },
    name: 'Workspace',
    component: () => import(/* webpackChunkName: "workspace" */ '../views/Home.vue'),
    beforeEnter: vueRouterGuard,
    props: (route) => {
      const {customerId, sectionId, synonymId} = route.params;
      return {
        customerId,
        sectionId,
        synonymId,
      }
    },
    children: [
      {
        path: '/:customerId/organizer',
        name: 'Organizer',
        props: true,
        component:  () => import(/* webpackChunkName: "layout" */ '../components/organizerView/OrganizerWidget.vue'),
        beforeEnter: vueRouterGuard,
      },
      {
        path: '/:customerId/writer',
        name: 'Writer',
        props: true,
        component:  () => import(/* webpackChunkName: "writer" */ '../components/writerView/WriterWidget.vue'),
        beforeEnter: vueRouterGuard,
      },
      {
        path: '/:customerId/settings',
        name: 'Settings',
        component:  () => import(/* webpackChunkName: "settings" */ '../components/Settings.vue'),
        beforeEnter: vueRouterGuard,
      },
      {
        path: '/:customerId/stories',
        name: 'Templates',
        component:  () => import(/* webpackChunkName: "templates" */ '../components/Templates.vue'),
        beforeEnter: vueRouterGuard,
      },
      {
        path: '/:customerId/text-overview',
        name: 'TextOverview',
        props: true,
        component:  () => import(/* webpackChunkName: "text-overview" */ '../components/TextOverview.vue'),
        beforeEnter: vueRouterGuard,
      },
      {
        path: '/:customerId/synonym-list',
        name: 'SynonymList',
        props: true,
        component: () => import(/* webpackChunkName: "synonym-list" */ '../components/SynonymListWidget.vue'),
        beforeEnter: vueRouterGuard,
      },
      {
        path: '/:customerId/synonym/:synonymId',
        name: 'Synonym',
        props: true,
        component: () => import(/* webpackChunkName: "synonym" */ '../components/SynonymAlternativeListWidget.vue'),
        beforeEnter: vueRouterGuard,
      },
      {
        path: '/:customerId/section/:sectionId',
        name: 'Section',
        component: () => import(/* webpackChunkName: "section" */ '../components/organizerView/SectionAlternativeListWidget.vue'),
        beforeEnter: vueRouterGuard,
        props: route => (
          {
            customerId: route.params.customerId,
            sectionId: route.params.sectionId,
            routingParams: JSON.stringify({
              linkedSectionAlternativeId: route.query.section_alternative,
              linkedTextAlternativeId: route.query.text_alternative,
            }),
          }
        ),
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "home" */ '../views/404.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router;
