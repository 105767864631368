<template>
  <svg
    class="icon"
    :class="`${cssClass}`"
    @click="onclick"
  >
    <title v-if="title">{{title}}</title>
    <use :xlink:href="`${iconFile}#${name}`" />
  </svg>
</template>

<script>
/**
 * @vuese
 * default svg icon component. Uses `/img/icons.svg` as svg source
 */
export default {
  name: 'icon',
  emits: ['click-icon'],
  data() {
    return {
      iconFile: require('@/assets/img/icons.svg'),
    };
  },
  props: {
    // icon name, have a look into `/img/icons.svg`
    // to find a svg icon id to fill in here
    name: {
      type: String,
      required: true,
    },
    cssClass: {
      type: String,
      default: '',
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  methods: {
    onclick() {
      this.$emit('click-icon');
    },
  },
};
</script>

<style lang="scss">
.icon {
  height: 1em;
  width: 1em;
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  pointer-events: none;
  vertical-align: middle;

  &.-edit {
    width: rem(30px);
    height: rem(30px);
  }

  &.-add {
    width: rem(40px);
    height: rem(40px);
  }

  &.-footer {
    width: rem(26px);
    margin-right: rem(5px);
  }

  &.-drag {
    color: #A9ADB0;

    .-active & {
      color: inherit;
    }
  }

  &.-toast {
    margin-right: rem(10px);
    height: rem(22px);
    width: rem(22px);
  }

  &.-date-request {
    height: rem(55px);
    width: rem(55px);
    filter: drop-shadow(0px 4px 10px rgba(134, 197, 255, 0.3));
  }

  &.-toggle {
    color: var(--bg-dark-1);
    transform: rotate(-90deg);

    .-hidden & {
      transform: rotate(90deg);
    }
  }

  &.-output-toggle {
    color: var(--bg-dark-1);
    transform: rotate(90deg);
    padding: rem(3px) 0 rem(3px) rem(3px);

    .-hidden & {
      transform: rotate(-90deg);
    }
  }

  &.-view-toggle {
    width: rem(15px);
    height: rem(15px);
  }
}
</style>
