export default {
  // add new customers here
  // do not forget to register the vue modules,
  // custom searches need to be registered in MainSearch.vue,
  // custom feedbacks need to be registered in Feedback.vue
  'demo': {
    workspace: 'DemoWorkspace',
    searchModule: 'DemoSearch',
    feedback: {
      feedbackModule: 'DemoFeedback',
      requiredFields: [],
    },
    signalSearch: false,
    published: true,
  },
  'onvista': {
    workspace: 'OnvistaWorkspace',
    searchModule: 'OnvistaSearch',
    feedback: {
      feedbackModule: 'OnvistaFeedback',
      requiredFields: ['stocks', 'dates'],
    },
    signalSearch: true,
    published: true,
  },
  'codevertise': {
    workspace: 'CodevertiseWorkspace',
    searchModule: 'CodevertiseSearch',
    feedback: {
      feedbackModule: 'DemoFeedback',
      requiredFields: [],
    },
    signalSearch: false,
    published: true,
  },
  'hochzeitsrausch': {
    workspace: 'HochzeitsrauschWorkspace',
    searchModule: 'HochzeitsrauschSearch',
    feedback: {
      feedbackModule: 'DemoFeedback',
      requiredFields: [],
    },
    signalSearch: true,
    published: true,
  }
}