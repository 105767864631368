<template>
  <footer class="footer" id="footer">
    <div class="footer__container">
      <p>© DIVISIO 2022</p>
      <div class="footer__links">
        <a class="footer__link" href="https://divis.io/kontakt" target="_blank">
          <Icon name="contact" css-class="-footer"/>
          <span>Kontakt</span>
        </a>
        <a class="footer__link" href="https://divis.io/imprint/" target="_blank">
          <Icon name="impress" css-class="-footer"/>
          <span>Impressum</span>
        </a>
        <a class="footer__link" href="https://divis.io/datenschutz/" target="_blank">
          <Icon name="agb" css-class="-footer"/>
          <span>Datenschutz</span>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import Icon from "@/components/generic/Icon";

export default {
  name: "Footer",
  components: {
    Icon,
  },
}
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width:100%;
  background-color: var(--bg-dark-1);
  color: #FEFEFE;
  z-index: 10;

  &__container {
    width: 100%;
    height: 120px;
    padding: 40px 20px;
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__links {}

  &__link {
    text-decoration: none;
    margin-right: rem(40px);

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:first-of-type {
      margin-right: rem(150px);
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>