import customers from '@/config/customers';

/**
 * Checks if customerId exists in config
 * @param id
 * @returns {boolean}
 */
function exists(id) {
  return !!customers[id];
}

/**
 * Checks if customer is published
 * @param id
 * @returns {boolean}
 */
function published(id) {
  return !!customers[id]?.published;
}


/**
 * Checks if customer is published
 * @param cookieName
 * @returns {boolean}
 */
function loggedIn(cookieName) {
  let d = new Date();
  d.setTime(d.getTime() + (1000));
  let expires = "expires=" + d.toUTCString();

  document.cookie = cookieName + "=new_value;path=/;" + expires;
  return document.cookie.indexOf(cookieName + '=') === -1;
}

/**
 * Vue Router Guard
 * Sets authorization if the customerId exists AND the customer is publshed.
 * If environment is "development" the authorization will pass when the customerId exists but is not published
 * @param to
 * @param from
 * @param next
 */
function vueRouterGuard(to, from, next) {
  const idExists = exists(to.params.customerId),
    isPublished = published(to.params.customerId),
    isDev = process.env.NODE_ENV === 'development',
    isLoggedIn = loggedIn('access_token'),
    auth = idExists && isDev && isLoggedIn || idExists && isPublished && isLoggedIn;
  if (!auth) {
    next({ name: 'Login' });
  } else {
    next();
  }
}

export { vueRouterGuard }