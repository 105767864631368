<template>
  <header class="header" id="header">
    <a class="logo" v-bind:href="`/#/${$route.params.customerId}/organizer`">
      <Icon name="logo" />
    </a>
  </header>
</template>

<script>
import Icon from "@/components/generic/Icon";

export default {
  name: "Header",
  components: {
    Icon,
  },
}
</script>
import Icon from '@/components/generic/Icon';

<style lang="scss" scoped>

.header {
  width:100%;
  min-height: 60px;
  max-height: 60px;
  background-color: var(--bg-dark-1);
  color: #FEFEFE;
  display: flex;
  position: relative;
}

.logo {
  width: rem(300px);
  justify-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8D8F91;

  svg {
    width: 100%;
    height: rem(22px);
  }

  &:hover,
  &:focus {
    transform: scaleX(1.02);
  }
}
</style>